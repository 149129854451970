.introduction {
  background-color: var(--text-dark);
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.9)),
    url("../../assets/hero.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.introduction .intro-item {
  min-height: 98vh;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  border-radius: 32px;
  color: var(--text-light);
  animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.cta-button {
  padding: 12px 24px;
  border-radius: 30px;
  font-size: 1.2rem;
  transition: background-color 0.3s ease-in-out;
}

.cta-button:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
