.approachesToSolutions {
  background-color: var(--text-light);
  padding: 2rem 0;
  /* background: linear-gradient(rgba(5, 77, 101, 0.93), rgba(4, 84, 110, 0.95)),
    url("../../assets/industries.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

.approachesToSolutions .container {
  min-height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-dark);
}

.approachesToSolutions .header {
  margin-bottom: 3rem;
}

.approachesToSolutions .item {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  gap: 2rem;
  margin-bottom: 3rem;
}

.approachesToSolutions .count {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--text-dark);
}
