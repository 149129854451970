/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

@font-face {
  font-family: "font-normal"; /* Name of the font you will use in CSS */
  src: url("./assets//fonts/Matter-Regular.ttf") format("truetype"); /* Path to your .ttf file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "font-bold";
  src: url("./assets/fonts/Matter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "font-light";
  src: url("./assets/fonts/Matter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "font-normal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  font-size: 1rem;
  font-size: clamp(1rem, -0.9230769230769231rem + 8.205128205128204vw, 1rem);
  color: var(--text-dark);
  /* overflow-x: hidden; */
}

.bold-text {
  font-family: "font-bold", sans-serif;
  font-weight: 700;
}

.light-text {
  font-family: "font-light", sans-serif;
  font-weight: 300;
}

h1 {
  font-size: 1rem;
  font-size: clamp(2rem, -0.9230769230769231rem + 8.205128205128204vw, 5rem);
}
