.identifyingChallenges {
  padding: 2rem 0;
  /* background-color: var(--primary);
  background: linear-gradient(rgba(235, 152, 61, 0.93), rgba(4, 84, 110, 0.95)),
    url("../../assets/industries.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

.identifyingChallenges .container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-dark);
}

.identifyingChallenges .header {
  margin-bottom: 3rem;
}

.identifyingChallenges .item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3rem;
}

.identifyingChallenges .count {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary);
  color: var(--primary);
}
