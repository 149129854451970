.contact {
  min-height: 90vh;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--text-light);
}

.map {
  height: 60vh;
}
