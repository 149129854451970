.footer {
  background-color: var(--border);
}

.footer .container .open {
  padding: 2rem 0;
}

.footer-closing {
  background-color: var(--primary-dark);
}

.footer-closing .container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  color: var(--text-light);
}
