:root {
  --primary: #eb983d; /* Base Primary Color */
  --primary-light: #bce7f5; /* Lighter shade of primary */
  --primary-dark: #1c0e03; /* Darker shade of primary */

  --accent-color: #f7b500; /* Bright accent color, warm yellow */
  --background: #fffaf5; /* Light background color */

  --text-dark: #1c1c1c; /* Dark text color */
  --text-light: #ffffff; /* Light text for contrast */

  --border-color: #d1d9df; /* Subtle gray for borders */
  --danger-color: #f05; /* Danger, error color */
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

h1 {
  font-weight: 700;
  line-height: 1.2;
}

p {
  margin: 0 auto;
  line-height: 1.6;
  margin-bottom: 0;
}

.text-danger {
  color: var(--danger);
}

.text-bold {
  font-weight: 600;
}

.text-disabled {
  opacity: 0.8;
}

/* list styles */
.list-group {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}

.list-group-item {
  background-color: transparent;
  border: 0;
  padding: 0;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.list-group-item:hover {
  font-weight: 600;
}

/* form styles */
.form-group label {
  margin-bottom: 0.5rem;
}

input.form-control,
textarea.form-control {
  border-radius: 40px;
  border: 1px solid var(--primary-dark);
  box-shadow: none;
  outline: none;
  background-color: transparent;
}

textarea.form-control {
  border-radius: 20px;
}

input.form-control:focus,
textarea.form-control:focus {
  border: 2px solid var(--primary);
  box-shadow: none;
  outline: none;
}

.btn {
  border-radius: 30px;
  font-weight: 600;
  font-size: 13px;
  border: 0;
  font-size: 1rem;
  transition: background-color 0.3s ease-in-out;
}

.btn-primary {
  background-color: var(--primary);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: var(--primary-dark);
}

/* card */

.card {
  background-color: var(--text-light);
  border: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  cursor: pointer;
  transition: border 0.3s ease;
}

.card:hover {
  opacity: 0.9;
}

.form-control-lg {
  min-height: 4rem;
}
