.industriesSector {
  background-color: var((--text-light));
}

.industriesSector .container {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.industriesSector .header-cover-mage {
  height: 20vh;
  background: linear-gradient(rgba(0, 0, 0, 5), rgba(0, 0, 0, 0.3)),
    url("../../../assets/industries.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.industriesSector .category-item {
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid var(--primary);
  padding: 0.3rem 0.5rem;
  border-radius: 1rem;
  display: inline-block; /* Keeps items in a row, within the card */
  max-width: 100%; /* Prevents overflow */
  white-space: nowrap; /* Prevents text from breaking to the next line */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Shows ellipsis for long text */
  box-sizing: border-box;
  flex-shrink: 0; /* Prevents the category item from shrinking */
  color: var(--primary);
}

.industriesSector .client-item {
  margin-bottom: 1rem;
  height: 200px;
}

.industriesSector .client-item .image {
  height: 80px;
}
