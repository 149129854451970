.industriesSectors {
  /* min-height: 90vh; */

  padding: 5rem 0;
  /* color: var(--text-light); */
}

.industriesSectors .container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.industriesSectors .sector-item {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allows the sector-item to grow dynamically based on content */
  /* min-height: 30vh; */
}

.industriesSectors .sector-item .card .header {
  height: 30vh;
  margin-bottom: 2rem;
  border-radius: 8px;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.8)),
    url("../../assets/industries.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.industriesSectors .card .header .logos-container {
  /* width: 3rem;
  height: 3rem; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--text-light);
  overflow: hidden;
}

.industriesSectors .sector-item .card .header .logo-ind {
  aspect-ratio: 2/2;
  width: 70%;
  object-fit: contain;
}

.industriesSectors .sector-item .description {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to two lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allows wrapping of lines */
}

.industriesSectors .sector-item .category-item {
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid var(--primary);
  padding: 0.3rem 0.5rem;
  border-radius: 1rem;
  display: inline-block; /* Keeps items in a row, within the card */
  max-width: 100%; /* Prevents overflow */
  white-space: nowrap; /* Prevents text from breaking to the next line */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Shows ellipsis for long text */
  box-sizing: border-box;
  flex-shrink: 0; /* Prevents the category item from shrinking */
  color: var(--primary);
}
