.team {
  min-height: 80vh;
  background-color: var(--background);
  padding: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-item {
  border: 0;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.team-item .body {
  height: 240px;
  background-color: var(--primary-light);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-item img {
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 30%;
}

.team-item .card-footer {
  background-color: var(--text-light);
  border: 0;
}
